import { getCookie } from "../helpers/auth";
import { REDUX_PERSIST } from "./localization";

let token = null;

export const getPersistedAuthToken = () => {
  if (getCookie()) {
    token = getCookie();
  }
  return token;
};

export const clearReduxPersist = () => {
  localStorage.removeItem(REDUX_PERSIST);
};
