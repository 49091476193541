import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Provider } from "react-redux";
import { initializeApollo } from "./apollo-client";
import { initializeStore } from "./src/utils/redux/store";
// eslint-disable-next-line react/display-name,react/prop-types

export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = initializeStore();
  const apolloClient = initializeApollo({ initialState: null, ctx: null });
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>{element}</ApolloProvider>
    </Provider>
  );
};
