import { isMobile, isTab } from "../../helpers/global";

export const initialUserState = {
  isUserLoggedIn: false,
  userDetails: null,
};

export const initialHelperState = {
  isMobile: isMobile(),
  isTab: isTab(),
};

export const initialCartState = {};

export const initialUserCoursesState = {
  courses: [],
  isCoursesLoading: true,
  series: [],
  coursePurchaseBill: {},
};

export const initialUserPlans = {
  plans: [],
  planPurchaseBill: {},
};

export const combinedInitialState = {
  user: initialUserState,
  helper: initialHelperState,
  cartDetails: initialCartState,
  userCourses: initialUserCoursesState,
  userPlans: initialUserPlans,
};

export const modalsInitialState = {
  authModal: false,
  plansModal: false,
  skillCoursePurchaseSuccess: false,
  internshipCoursePurchaseModal: false,
  internshipCoursePurchaseSuccessModal: false,
};

export const statesToBePersisted = ["user", "cartDetails"];
