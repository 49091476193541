import { combineReducers } from "redux";
import cartDetailsReducer from "./cartDetailsReducer";
import helperReducer from "./helperReducer";
import userCoursesReducer from "./userCoursesReducer";
import userReducer from "./userReducer";
import userPlansReducer from "./userPlansReducer";
import modalReducer from "./modalsReducer";

const reducers = combineReducers({
  user: userReducer,
  helper: helperReducer,
  cartDetails: cartDetailsReducer,
  userCourses: userCoursesReducer,
  userPlans: userPlansReducer,
  modals: modalReducer,
});

export default reducers;
