export const variants = {
  ctaButton: "cta",
  primaryButton: "primary",
  secondaryButton: "secondary",
  outlineDarkButton: "outlineDark",
  outlineLiteButton: "outlineLite",
  ghostButton: "ghost",
  positiveToastMessage: "positive",
  negativeToastMessage: "negative",
  warningToastMessage: "warning",
  error: "500",
  accessDenied: "403",
  textInput: "text",
  numberInput: "number",
  basicPlanType: "basic",
  advancePlanType: "advance",
  professionalPlanType: "professional",
  proPlanType: "pro",
  approvedMessage: "approved",
  feedbackMessage: "feedback",
  errorInlineMessage: "error",
  infoInlineMessage: "info",
  warningInlineMessage: "warning",
  authNavbar: "auth",
  courseNavbar: "course",
  neutralNavbar: "neutral",
  infoMessage: "info",
  successMessage: "success",
  errorMessage: "error",
  warningMessage: "warning",
  timeOutMessage: "timeout",
  lesson: "lesson",
  project: "project",
  underReview: "pending",
  feedbackSent: "feedback_sent",
  approved: "approved",
  seniorCoach: "senior_coach",
  juniorCoach: "junior_coach",
  learner: "learner",
  passed: "passed",
  failed: "failed",
  finalAssessment: "final",
  midAssessment: "mid_chapter",
};
