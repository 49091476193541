export const ACTION_TYPES = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  UPDATE_USER: "UPDATE_USER",
  RESET_USER_STATE: "RESET_USER_STATE",
  SHOW_TOAST_MESSAGE: "SHOW_TOAST_MESSAGE",
  SET_COURSE_LIST: "SET_COURSE_LIST",
  UPDATE_COURSE_LIST: "UPDATE_COURSE_LIST",
  SET_COURSE_DETAILS: "SET_COURSE_DETAILS",
  UPDATE_COURSE_DETAILS: "UPDATE_COURSE_DETAILS",
  SET_NOTE_LIST: "SET_NOTE_LIST",
  OPEN_ADD_NOTE_ACTION: "OPEN_ADD_NOTE_ACTION",
  DISCARD_ADD_NOTE_ACTION: "DISCARD_ADD_NOTE_ACTION",
  UPDATE_NOTE_LIST: "UPDATE_NOTE_LIST",
  UPDATE_NOTE: "UPDATE_NOTE",
  DELETE_NOTE: "DELETE_NOTE",
  SET_PROJECT_LIST: "SET_PROJECT_LIST",
  UPDATE_PROJECT_LIST: "UPDATE_PROJECT_LIST",
  UPDATE_PROJECT: "UPDATE_PROJECT",
  SET_SYLLABUS_LIST: "SET_SYLLABUS_LIST",
  UPDATE_SYLLABUS_LIST: "UPDATE_SYLLABUS_LIST",
  UPDATE_SYLLABUS_CHAPTER: "UPDATE_SYLLABUS_CHAPTER",
  UPDATE_SYLLABUS_LESSON: "UPDATE_SYLLABUS_LESSON",
  SET_ACTIVE_CHAPTER_KEY: "SET_ACTIVE_CHAPTER_KEY",
  SET_ACTIVE_LESSON_KEY: "SET_ACTIVE_LESSON_KEY",
  SET_NOTES_FILTER: "SET_NOTES_FILTER",
  INCREMENT_ASSESSMENT_QUESTION_PAGE: "INCREMENT_ASSESSMENT_QUESTION_PAGE",
  DECREMENT_ASSESSMENT_QUESTION_PAGE: "DECREMENT_ASSESSMENT_QUESTION_PAGE",
  SET_ASSESSMENT_QUESTION_PAGE: "SET_ASSESSMENT_QUESTION_PAGE",
  RESET_ASSESSMENT_QUESTION_PAGE: "RESET_ASSESSMENT_QUESTION_PAGE",
  UPDATE_ASSESSMENT_STATE: "UPDATE_ASSESSMENT_STATE",
  SET_ASSESSMENT_TOTAL_PAGES: "SET_ASSESSMENT_TOTAL_PAGES",
  SET_ASSESSMENT_QUESTION_LIST: "SET_ASSESSMENT_QUESTION_LIST",
  SHOW_ASSESSMENT_MESSAGE: "SHOW_ASSESSMENT_MESSAGE",
  CLOSE_ASSESSMENT_MESSAGE: "HIDE_ASSESSMENT_MESSAGE",
  SET_ANSWER_RESULT: "SET_ANSWER_RESULT",
  RESET_ANSWER_RESULT: "RESET_ANSWER_RESULT",
  SET_QUESTION_TIMEOUT_STATUS: "SET_QUESTION_TIMEOUT_STATUS_STATUS",
  SET_CANONICAL_ASSESSMENT: "SET_CANONICAL_ASSESSMENT",
  SET_ASSESSMENT_FINAL_SCORE: "SET_ASSESSMENT_FINAL_SCORE",
  RESET_ASSESSMENT: "RESET_ASSESSMENT",
  SET_COURSE_PROGRESS_DATA: "SET_COURSE_PROGRESS_DATA",
  UPDATE_COURSE_PROGRESS_DATA: "UPDATE_COURSE_PROGRESS_DATA",
  SET_CART_DETAILS: "SET_CART_DETAILS",
  UPDATE_CART_DETAILS: "UPDATE_CART_DETAILS",
  RESET_CART_DETAILS: "RESET_CART_DETAILS",
  SET_USER_COURSES: "SET_USER_COURSES",
  SET_USER_SERIES_COURSES: "SET_USER_SERIES_COURSES",
  SET_USER_PLANS: "SET_USER_PLANS",
  OPEN_AUTH_MODAL: "OPEN_AUTH_MODAL",
  CLOSE_AUTH_MODAL: "CLOSE_AUTH_MODAL",
  OPEN_PLANS_MODAL: "OPEN_PLANS_MODAL",
  CLOSE_PLANS_MODAL: "CLOSE_PLANS_MODAL",
  OPEN_SKILL_COURSE_PURCHASE_SUCCESS_MODAL:
    "OPEN_SKILL_COURSE_PURCHASE_SUCCESS_MODAL",
  CLOSE_SKILL_COURSE_PURCHASE_SUCCESS_MODAL:
    "CLOSE_SKILL_COURSE_PURCHASE_SUCCESS_MODAL",
  SET_PLAN_PURCHASE_BILL: "SET_PLAN_PURCHASE_BILL",
  OPEN_INTERNSHIP_COURSE_PURCHASE_MODAL:
    "OPEN_INTERNSHIP_COURSE_PURCHASE_MODAL",
  CLOSE_INTERNSHIP_COURSE_PURCHASE_MODAL:
    "CLOSE_INTERNSHIP_COURSE_PURCHASE_MODAL",
  SET_COURSE_PURCHASE_BILL: "SET_COURSE_PURCHASE_BILL",
  OPEN_INTERNSHIP_COURSE_PURCHASE_SUCCESS_MODAL:
    "OPEN_INTERNSHIP_COURSE_PURCHASE_SUCCESS_MODAL",
  CLOSE_INTERNSHIP_COURSE_PURCHASE_SUCCESS_MODAL:
    "CLOSE_INTERNSHIP_COURSE_PURCHASE_SUCCESS_MODAL",
};
