export const HTTPS = "https://";

export const ROOT_PATH = "/";
export const COURSES_PATH = "courses";
export const SERIES_PATH = "series";
export const SKILL_COURSE_PATH = "skill-courses";
export const CATEGORIES_PATH = "categories";
export const INTERNSHIP_PROGRAM_PATH = "internship-program";
// coach
export const SENIOR_COACH = "senior_coach";

//Base domain
export const BASE_DOMAIN = ".unschool.in";

// Image Placeholder
export const IMAGE_PLACEHOLDER =
  "https://www.slntechnologies.com/wp-content/uploads/2017/08/ef3-placeholder-image.jpg";

// Counts
export const INITIAL_LEARNER_COUNT = 5000;
export const INITIAL_LEARNERS_UNDER_COACH_COUNT = 5000;
export const INITIAL_COURSE_REVIEWERS = 1500;
export const INITIAL_COACH_REVIEWS = 1500;
export const INITIAL_COACH_RATINGS = 4.0;
export const INITIAL_COURSE_RATINGS = 4.0;

// Redux
export const REDUX_PERSIST = "redux-persist-usm";

//Auth
export const AUTH_TOKEN = "auth-token";
export const SET_COOKIE = "Set-Cookie";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const VERIFY_OTP = "verify-otp";
export const ADD_USER_DETAILS = "add-user-details";
export const FIRST_LOGIN = "__fl";

//S3 Image CDN paths
export const IMAGE_CDN_ENDPOINT =
  HTTPS + process.env.GATSBY_PUBLIC_IMAGE_CDN_DOMAIN + "/assets";

//Marketing Folder path
export const MARKETING_IMAGE_PATH = IMAGE_CDN_ENDPOINT + "/marketing-images";

export const AWS_MEMBER_IMAGE_PATH =
  MARKETING_IMAGE_PATH + "/aws-member-badge.jpeg";
export const NASSCOM_IMAGE_PATH = MARKETING_IMAGE_PATH + "/nasscom.png";
export const SKILL_AP_IMAGE_PATH = MARKETING_IMAGE_PATH + "/skill-ap.png";
export const STARTUP_PORTAL_IMAGE_PATH =
  MARKETING_IMAGE_PATH + "/startup-portal-logo.png";
export const TASK_IMAGE_PATH = MARKETING_IMAGE_PATH + "/task.png";
export const VILLAGE_CAPITAL_IMAGE_PATH = MARKETING_IMAGE_PATH + "/vilcap.png";
export const APSSDC_IMAGE_PATH = MARKETING_IMAGE_PATH + "/apssdc.png";
export const STARTUP_INDIA_IMAGE_PATH =
  MARKETING_IMAGE_PATH + "/startup-india.png";
export const MIGUEL_IMAGE_PATH =
  MARKETING_IMAGE_PATH + "/aptweb_72-copy-miguel-magalhaes.jpg";
export const POOJA_IMAGE_PATH = MARKETING_IMAGE_PATH + "/pooja-pathak.jpg";
export const HIMANSHU_IMAGE_PATH =
  MARKETING_IMAGE_PATH + "/himanshu-raghani.jpg";

//Job companies path
export const JOB_COMPANIES_IMAGE_PATH = MARKETING_IMAGE_PATH + "/job-companies";
export const ACELOT_IMAGE_PATH = JOB_COMPANIES_IMAGE_PATH + "/acelot.png";
export const AVSOY_IMAGE_PATH = JOB_COMPANIES_IMAGE_PATH + "/avsoy.png";
export const BDI_IMAGE_PATH = JOB_COMPANIES_IMAGE_PATH + "/bdi-logo.png";
export const CINEMA_FACTORY_IMAGE_PATH =
  JOB_COMPANIES_IMAGE_PATH + "/cinema-factory.png";
export const FORESIET_IMAGE_PATH = JOB_COMPANIES_IMAGE_PATH + "/foresiet.png";
export const GORDIAN_IMAGE_PATH = JOB_COMPANIES_IMAGE_PATH + "/gordian.png";
export const SOCIO_IMAGE_PATH = JOB_COMPANIES_IMAGE_PATH + "/socio.png";
export const ULTRACURES_IMAGE_PATH =
  JOB_COMPANIES_IMAGE_PATH + "/ultracures.png";

//Industry coaches path
export const INDUSTRY_COACHES_IMAGE_PATH =
  MARKETING_IMAGE_PATH + "/industry-coaches";
export const COCA_COLA_IMAGE_PATH =
  INDUSTRY_COACHES_IMAGE_PATH + "/coca-cola-logo.png";
export const DADB_IMAGE_PATH = INDUSTRY_COACHES_IMAGE_PATH + "/dadb-logo.png";
export const FARM_IMAGE_PATH = INDUSTRY_COACHES_IMAGE_PATH + "/farm-logo.png";
export const NEARBUY_IMAGE_PATH =
  INDUSTRY_COACHES_IMAGE_PATH + "/nearbuy-logo.png";
export const SRM_IMAGE_PATH = INDUSTRY_COACHES_IMAGE_PATH + "/srm-logo.png";
export const TATA_IMAGE_PATH = INDUSTRY_COACHES_IMAGE_PATH + "/tata-logo.png";
export const TEDX_IMAGE_PATH = INDUSTRY_COACHES_IMAGE_PATH + "/tedx-logo.png";

//S3 SVG CDN paths
export const SVG_CDN_ENDPOINT = HTTPS + process.env.GATSBY_SVG_CDN_DOMAIN;
export const ERROR_SVG_PATH = SVG_CDN_ENDPOINT + "/broken.svg";
export const COURSES_SVG_PATH = SVG_CDN_ENDPOINT + "/courses.svg";
export const PAGE_NOT_FOUND_SVG_PATH = SVG_CDN_ENDPOINT + "/lost.svg";
export const NOTIFICATIONS_SVG_PATH =
  SVG_CDN_ENDPOINT + "/notifications-read.svg";
export const UPGRADE_SVG_PATH = SVG_CDN_ENDPOINT + "/upgrade.svg";
export const AUDIO_LESSON_SVG_PATH = SVG_CDN_ENDPOINT + "/audio-lesson.svg";
export const EMPTY_CART_SVG_PATH = SVG_CDN_ENDPOINT + "/empty-cart.svg";

// LMS Out-links
export const LMS_PLATFORM_OUT_LINK =
  process.env.GATSBY_APP_ENV === "production"
    ? "https://beta-learn.unschool.in/"
    : "https://staging-learn.unschool.in/";

export const INTERNSHIPS_URL = "https://internships.unschool.in/";
export const BECOME_A_COACH_FORM_URL = "https://forms.gle/pWv5eKF3x1RawgR18";

export const INTERNSHIP_PROGRAM = "internship_program";

export const SKILL_COURSE = "skill_courses";

export const COURSE_ITEM = "course_item";

export const PLAN_ITEM = "plan_item";

export const COURSE_AND_PLAN_ITEM = "course_and_plan_item";

export const LOADING = "loading";

export const HAS_SUBSCRIBED_TO_PLAN = "has_subscribed_to_plan";

export const HAS_NOT_SUBSCRIBED_TO_PLAN = "has_not_subscribed_to_plan";

export const HAS_FREE_PLAN = "has_free_plan";

export const HAS_NO_FREE_PLAN = "has_no_free_plan";

export const SITE_NAME =
  process.env.GATSBY_APP_ENV === "production" ? "unschool" : "unschool-test";

// skill courses
export const SKILL_COURSE_IMAGE_CDN_ENDPOINT =
  HTTPS + process.env.GATSBY_PUBLIC_IMAGE_CDN_DOMAIN + "/skill_courses";

// ***************HERO
export const SKILL_HERO_IMAGE_PATH =
  SKILL_COURSE_IMAGE_CDN_ENDPOINT + "/Hero/skill_hero.jpg";

// **************Powered by
export const POWERED_BY_IMAGE_PATH =
  SKILL_COURSE_IMAGE_CDN_ENDPOINT + "/powered_by_unschool";

export const POWERED_LINKEDIN = POWERED_BY_IMAGE_PATH + "/linkedin_.png";
export const POWERED_LSQ = POWERED_BY_IMAGE_PATH + "/lsq.png";
export const POWERED_YC = POWERED_BY_IMAGE_PATH + "/yc_.png";
export const POWERED_NEAR = POWERED_BY_IMAGE_PATH + "/near_.png";
export const POWERED_VC = POWERED_BY_IMAGE_PATH + "/villagecapital_.png";
export const POWERED_MM = POWERED_BY_IMAGE_PATH + "/mailmodo_.png";
export const POWERED_AWS = POWERED_BY_IMAGE_PATH + "/aws.png";
export const POWERED_LINUX = POWERED_BY_IMAGE_PATH + "/linux.png";
export const POWERED_PWC = POWERED_BY_IMAGE_PATH + "/pwc.png";
export const POWERED_FOOTBALL = POWERED_BY_IMAGE_PATH + "/football.png";
export const POWERED_FCGOA = POWERED_BY_IMAGE_PATH + "/fc_goa.png";
export const POWERED_GLOCAL = POWERED_BY_IMAGE_PATH + "/glocal.png";

// ***************courses
export const COURSE_IMAGE_PATH = SKILL_COURSE_IMAGE_CDN_ENDPOINT + "/courses";

export const COURSE_FOOTBALL = COURSE_IMAGE_PATH + "/Footballforbeginners.jpg";
export const COURSE_NOSQL_INTRO = COURSE_IMAGE_PATH + "/BigData01.jpg";
export const COURSE_ADOBE_TUTORIAL = COURSE_IMAGE_PATH + "/Adobe_tuts.jpg";
export const COURSE_AI = COURSE_IMAGE_PATH + "/Artificial_Intelligence.jpg";
export const COURSE_AWS_CLOUD1 = COURSE_IMAGE_PATH + "/AWSCloudComputing.jpg";
export const COURSE_AWS_CLOUD2 = COURSE_IMAGE_PATH + "/AWSCloudComputing_.jpg";
export const COURSE_BIG_DATA = COURSE_IMAGE_PATH + "/BigData.jpg";
export const COURSE_CAREER_PLANNING = COURSE_IMAGE_PATH + "/CareerPlanning.jpg";
export const COURSE_CONTENT_WRITING = COURSE_IMAGE_PATH + "/ContetnWriting.png";
export const COURSE_DIVING_INTO_CP = COURSE_IMAGE_PATH + "/Cplus.jpg";
export const COURSE_CP_ARRAYS = COURSE_IMAGE_PATH + "/CplusArrays.jpg";
export const COURSE_MAGIC = COURSE_IMAGE_PATH + "/Magic.png";
export const COURSE_SOFTSKILLS1 = COURSE_IMAGE_PATH + "/Softskills1.jpg";
export const COURSE_SOFTSKILLS2 = COURSE_IMAGE_PATH + "/Softskills2.jpg";
export const COURSE_WEBAUDIT = COURSE_IMAGE_PATH + "/WebAudit.jpg";
export const COURSE_MARKETING_3P = COURSE_IMAGE_PATH + "/Miguel.jpg";
export const COURSE_MARKETING_ANALYSIS = COURSE_IMAGE_PATH + "/Miguel_2.jpg";
export const COURSE_PSYCHOLOGY = COURSE_IMAGE_PATH + "/Psychology.jpg";
export const COURSE_SEM = COURSE_IMAGE_PATH + "/SEM.jpg";
export const COURSE_DESIGN_THINKING =
  COURSE_IMAGE_PATH + "/Design-Thinking.jpg";
export const COURSE_STOCK = COURSE_IMAGE_PATH + "/Stock-Market.jpg";
export const COURSE_OM = COURSE_IMAGE_PATH + "/OperationsManagement.jpg";
export const COURSE_PD = COURSE_IMAGE_PATH + "/ProductDevelopment.jpg";
export const COURSE_PUBLIC_RELATION =
  COURSE_IMAGE_PATH + "/PublicRelations.jpg";
export const COURSE_IOT = COURSE_IMAGE_PATH + "/IndustrialIoT(IIoT).jpg";
export const COURSE_ETHICAL_HACKING = COURSE_IMAGE_PATH + "/EthicalHacking.jpg";
export const COURSE_GAME_DEV = COURSE_IMAGE_PATH + "/GameDevelopment.jpg";
export const COURSE_CYBER_SECURITY = COURSE_IMAGE_PATH + "/CyberSecurity.jpg";
export const COURSE_TENSOR_FLOW =
  COURSE_IMAGE_PATH + "/IntroductiontoTensorflow_.jpg";
export const COURSE_LINUX = COURSE_IMAGE_PATH + "/Linux.jpg";
export const COURSE_CPLUS_ARRAY = COURSE_IMAGE_PATH + "/CplusArrays.jpg";
export const COURSE_PYTHON_TUTORIAL =
  COURSE_IMAGE_PATH + "/PythonTutorials.jpg";
export const COURSE_WEB_DEV = COURSE_IMAGE_PATH + "/WebDevelopment.jpg";
export const COURSE_JAVA = COURSE_IMAGE_PATH + "/IntroductiontoJava.jpg";
export const COURSE__PYGAME = COURSE_IMAGE_PATH + "/PygameTutorial.jpg";
export const COURSE_DATA_SCIENCE = COURSE_IMAGE_PATH + "/DataScience.jpg";
export const COURSE_STATISTICS = COURSE_IMAGE_PATH + "/Statistics_.jpg";

// ************* journey section - skill courses

export const SKILL_JOURNEY_IMAGE_PATH =
  SKILL_COURSE_IMAGE_CDN_ENDPOINT + "/journey";

export const SKILL_JOURNEY1_IMAGE = SKILL_JOURNEY_IMAGE_PATH + "/journey1.jpg";
export const SKILL_JOURNEY2_IMAGE = SKILL_JOURNEY_IMAGE_PATH + "/journey2.jpg";
export const SKILL_JOURNEY3_IMAGE = SKILL_JOURNEY_IMAGE_PATH + "/journey3.jpg";

// ************* coach section - skill courses

export const SKILL_COACH_IMAGE_PATH =
  SKILL_COURSE_IMAGE_CDN_ENDPOINT + "/coaches";

export const SKILL_ALEX = SKILL_COACH_IMAGE_PATH + "/Alex.png";
export const SKILL_ARUNA = SKILL_COACH_IMAGE_PATH + "/Aruna.png";
export const SKILL_HARSHITA = SKILL_COACH_IMAGE_PATH + "/Harshita.png";
export const SKILL_NIKANKSHA = SKILL_COACH_IMAGE_PATH + "/Nikanksha.png";
export const SKILL_MIGUEL = SKILL_COACH_IMAGE_PATH + "/Miguel.png";
export const SKILL_PRIYA = SKILL_COACH_IMAGE_PATH + "/Priya.png";
export const SKILL_LOUISE = SKILL_COACH_IMAGE_PATH + "/Louise.png";
export const SKILL_ZAHIN = SKILL_COACH_IMAGE_PATH + "/Zahin.png";
export const SKILL_KUNAL = SKILL_COACH_IMAGE_PATH + "/Kunal.png";
export const SKILL_TUSHAR = SKILL_COACH_IMAGE_PATH + "/Thushar.png";
export const SKILL_NISHTHA = SKILL_COACH_IMAGE_PATH + "/Nishitha.png";
export const SKILL_HIMANSHU = SKILL_COACH_IMAGE_PATH + "/Himanshu.jpg";
export const SKILL_SID = SKILL_COACH_IMAGE_PATH + "/Sid.png";
export const SKILL_ALEX_LOGO = SKILL_COACH_IMAGE_PATH + "/logos/alex.png";
export const SKILL_ARUNA_LOGO = SKILL_COACH_IMAGE_PATH + "/logos/aruna.png";
export const SKILL_SID_LOGO = SKILL_COACH_IMAGE_PATH + "/logos/british.png";
export const SKILL_HIMANSHU_LOGO = SKILL_COACH_IMAGE_PATH + "/logos/kotak.png";
export const SKILL_KUNAL_LOGO = SKILL_COACH_IMAGE_PATH + "/logos/kunarN.png";
export const SKILL_NIKANKSHA_LOGO =
  SKILL_COACH_IMAGE_PATH + "/logos/nikansha.png";
export const SKILL_MIGUEL_LOGO = SKILL_COACH_IMAGE_PATH + "/logos/Png.png";
export const SKILL_PRIYA_LOGO = SKILL_COACH_IMAGE_PATH + "/logos/priya.png";
export const SKILL_THUSHAR_LOGO = SKILL_COACH_IMAGE_PATH + "/logos/sony.png";
export const SKILL_NISHTHA_LOGO = SKILL_COACH_IMAGE_PATH + "/logos/tcs.png";
export const SKILL_ZAHIN_LOGO = SKILL_COACH_IMAGE_PATH + "/logos/undp.png";

// ************** testinominal - skill course
export const SKILL_TESTIMONIAL = SKILL_COACH_IMAGE_PATH + "/testimonila.webp";
export const SKILL_TESTIMONIAL_QUOTE = SKILL_COACH_IMAGE_PATH + "/quote_.png";

// ************** Business - skill course

export const SKILL_BUSINESS_IMAGE_PATH =
  SKILL_COURSE_IMAGE_CDN_ENDPOINT + "/business";

export const SKILL_FINANCEPEER_LOGO =
  SKILL_BUSINESS_IMAGE_PATH + "/financepeer.png";
export const SKILL_GLOCAL_LOGO = SKILL_BUSINESS_IMAGE_PATH + "/glocal.png";
export const SKILL_NASCOM_LOGO = SKILL_BUSINESS_IMAGE_PATH + "/nascom.png";
export const SKILL_SKILL_LOGO = SKILL_BUSINESS_IMAGE_PATH + "/skill.png";
export const SKILL_SPRING_LOGO = SKILL_BUSINESS_IMAGE_PATH + "/spring.png";
export const SKILL_TASK_LOGO = SKILL_BUSINESS_IMAGE_PATH + "/task.png";
export const SKILL_THUB_LOGO = SKILL_BUSINESS_IMAGE_PATH + "/thub.png";
export const SKILL_WOMEN_LOGO =
  SKILL_BUSINESS_IMAGE_PATH + "/businessWomen.jpg";
export const SKILL_UNSCHOOL_DARK_LOGO =
  SKILL_BUSINESS_IMAGE_PATH + "/Unschool_logo_dark.png";
// // JOB PROGRAM ///////////////////////////////////

export const JOB_PROGRAM_IMAGE_CDN_ENDPOINT =
  HTTPS + process.env.GATSBY_PUBLIC_IMAGE_CDN_DOMAIN + "/Job_program";

// ***************program overview

export const JOB_PROGRAM_OVERVIEW_IMAGE_PATH =
  JOB_PROGRAM_IMAGE_CDN_ENDPOINT + "/programOverview";

export const JP_CERTIFICATE =
  JOB_PROGRAM_OVERVIEW_IMAGE_PATH + "/certificate.png";

export const JP_EXPERT = JOB_PROGRAM_OVERVIEW_IMAGE_PATH + "/expert.png";

export const JP_LIVE = JOB_PROGRAM_OVERVIEW_IMAGE_PATH + "/live.png";

export const JP_TRAINING = JOB_PROGRAM_OVERVIEW_IMAGE_PATH + "/training.png";

export const JP_LEARNING = JOB_PROGRAM_OVERVIEW_IMAGE_PATH + "/learning.png";

export const JP_SOLVE = JOB_PROGRAM_OVERVIEW_IMAGE_PATH + "/solve.png";

export const JP_VIDEO = JOB_PROGRAM_OVERVIEW_IMAGE_PATH + "/video.png";

export const JP_ASSISTANCE =
  JOB_PROGRAM_OVERVIEW_IMAGE_PATH + "/assistance.png";

// ***************tools
export const JOB_PROGRAM_TOOLS_IMAGE_PATH =
  JOB_PROGRAM_IMAGE_CDN_ENDPOINT + "/tools";

export const JP_GOOGLE_ANALYTICS =
  JOB_PROGRAM_TOOLS_IMAGE_PATH + "/GoogleAnalytics.jpg";

export const JP_HOOT = JOB_PROGRAM_TOOLS_IMAGE_PATH + "/Hootsuite.jpg";
export const JP_HUBSPOT = JOB_PROGRAM_TOOLS_IMAGE_PATH + "/Hubspot.jpg";
export const JP_MAILCHIMP = JOB_PROGRAM_TOOLS_IMAGE_PATH + "/MailChimp.jpg";
export const JP_META = JOB_PROGRAM_TOOLS_IMAGE_PATH + "/Meta.jpg";
export const JP_SPYFLU = JOB_PROGRAM_TOOLS_IMAGE_PATH + "/SpyFu.jpg";
export const JP_TWITTER =
  JOB_PROGRAM_TOOLS_IMAGE_PATH + "/Twitteranalytics.jpg";

export const JP_WORDPRESS = JOB_PROGRAM_TOOLS_IMAGE_PATH + "/WordPress.jpg";
export const JP_SKILL_TICK = JOB_PROGRAM_TOOLS_IMAGE_PATH + "/Tickmark.png";

// ***************companies
export const JOB_PROGRAM_COMPANIES_IMAGE_PATH =
  JOB_PROGRAM_IMAGE_CDN_ENDPOINT + "/companies";

export const JP_AMAZON = JOB_PROGRAM_COMPANIES_IMAGE_PATH + "/Amazon-100.jpg";
export const JP_ACCENTURE =
  JOB_PROGRAM_COMPANIES_IMAGE_PATH + "/Accenture-100.jpg";
export const JP_DELTA = JOB_PROGRAM_COMPANIES_IMAGE_PATH + "/Delta-100.jpg";
export const JP_HOTSTAR =
  JOB_PROGRAM_COMPANIES_IMAGE_PATH + "/Disney+hotstar-100.jpg";
export const JP_FRESHWORKS =
  JOB_PROGRAM_COMPANIES_IMAGE_PATH + "/Freshworks-100.jpg";
export const JP_MEDIANET =
  JOB_PROGRAM_COMPANIES_IMAGE_PATH + "/Medianet-100.jpg";
export const JP_SIEMENS = JOB_PROGRAM_COMPANIES_IMAGE_PATH + "/Siemens-100.jpg";
export const JP_SLX = JOB_PROGRAM_COMPANIES_IMAGE_PATH + "/SLX-100.jpg";
export const JP_XIAOMI = JOB_PROGRAM_COMPANIES_IMAGE_PATH + "/Xiaomi-100.jpg";

// ***************coaches
export const JOB_PROGRAM_COACHES_IMAGE_PATH =
  JOB_PROGRAM_IMAGE_CDN_ENDPOINT + "/coaches";

// MEGHA
export const JP_MEGHA_PIC = JOB_PROGRAM_COACHES_IMAGE_PATH + "/megha.jpg";
export const JP_MEGHA_COLLEGE =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/megha_college.jpg";
export const JP_MEGHA_WORK1 =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/megha_work1.jpg";
export const JP_MEGHA_WORK2 =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/megha_work2.jpg";
export const JP_MEGHA_WORK3 =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/megha_work3.jpg";

//LINKEDIN LOGO
export const LINKEDIN_LOGO =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/linkined_logo_.png";

//SAMDHISH

export const JP_SAMDHISH_PIC = JOB_PROGRAM_COACHES_IMAGE_PATH + "/samdhish.jpg";
export const JP_SAMDHISH_COLLEGE =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/samdhish_college.jpg";
export const JP_SAMDHISH_WORK1 =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/samdhish_work1.jpg";
export const JP_SAMDHISH_WORK2 =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/samdhish_work2.jpg";
export const JP_SAMDHISH_WORK3 =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/samdhish_work3.jpg";

// VIPIN

export const JP_VIPIN_PIC = JOB_PROGRAM_COACHES_IMAGE_PATH + "/vipin.jpg";
export const JP_VIPIN_COLLEGE =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/vipin_college.jpg";
export const JP_VIPIN_WORK1 =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/vipin_work1.jpg";
export const JP_VIPIN_WORK2 =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/vipin_work2.jpg";
export const JP_VIPIN_WORK3 =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/vipin_work3.jpg";

// ARVIND

export const JP_ARVIND_PIC = JOB_PROGRAM_COACHES_IMAGE_PATH + "/arvind.jpg";
export const JP_ARVIND_COLLEGE =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/arvind_college.jpg";
export const JP_ARVIND_WORK1 =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/arvind_work1.jpg";
export const JP_ARVIND_WORK2 =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/arvind_work2.jpg";
export const JP_ARVIND_WORK3 =
  JOB_PROGRAM_COACHES_IMAGE_PATH + "/arvind_work3.jpg";

// curicullum ********************

export const JOB_PROGRAM_CURRICULUM_IMAGE_PATH =
  JOB_PROGRAM_IMAGE_CDN_ENDPOINT + "/curriculum_section";

export const JP_TRAINING_PIC =
  JOB_PROGRAM_CURRICULUM_IMAGE_PATH + "/training.png";
export const JP_CASE_PIC =
  JOB_PROGRAM_CURRICULUM_IMAGE_PATH + "/case_studies.png";
export const JP_TOOLS_PIC = JOB_PROGRAM_CURRICULUM_IMAGE_PATH + "/tools.png";
export const JP_LIVE_PIC =
  JOB_PROGRAM_CURRICULUM_IMAGE_PATH + "/live_session.png";

export const JP_MOCKUP_PIC = JOB_PROGRAM_CURRICULUM_IMAGE_PATH + "/mockup.png";

// projects***********************

export const JOB_PROGRAM_PROJECTS_IMAGE_PATH =
  JOB_PROGRAM_IMAGE_CDN_ENDPOINT + "/projects";

export const JP_BOOKING_PIC =
  JOB_PROGRAM_PROJECTS_IMAGE_PATH + "/Bookingcom.png";
export const JP_BUZZFEED_PIC =
  JOB_PROGRAM_PROJECTS_IMAGE_PATH + "/BuzzFeed.png";
export const JP_GOOGLEANALYTICS_PIC =
  JOB_PROGRAM_PROJECTS_IMAGE_PATH + "/GoogleAds.png";
export const JP_OYO_PIC = JOB_PROGRAM_PROJECTS_IMAGE_PATH + "/OYO.png";
export const JP_DOMINOZ_PIC = JOB_PROGRAM_PROJECTS_IMAGE_PATH + "/Dominos.png";
export const JP_MASTERCARD_PIC =
  JOB_PROGRAM_PROJECTS_IMAGE_PATH + "/MasterCard.png";
export const JP_TRIPADVISOR_PIC =
  JOB_PROGRAM_PROJECTS_IMAGE_PATH + "/TripAdvisor.png";
export const JP_ZOMATO_PIC = JOB_PROGRAM_PROJECTS_IMAGE_PATH + "/Zomato.png";

// journey***********************

export const JOB_PROGRAM_JOURNEY_IMAGE_PATH =
  JOB_PROGRAM_IMAGE_CDN_ENDPOINT + "/journey";

export const JP_JOURNEY_DESKTOP_PIC =
  JOB_PROGRAM_JOURNEY_IMAGE_PATH + "/journey_desktop.png";

export const JP_JOURNEY_MOBILE_PIC =
  JOB_PROGRAM_JOURNEY_IMAGE_PATH + "/journey_mobile.png";

// testimonials ****************************

export const JOB_PROGRAM_TESTIMONIAL_IMAGE_PATH =
  JOB_PROGRAM_IMAGE_CDN_ENDPOINT + "/testimonials";

export const JP_DULAM_PIC = JOB_PROGRAM_TESTIMONIAL_IMAGE_PATH + "/dulam.jpg";

export const JP_DULAM_C_PIC =
  JOB_PROGRAM_TESTIMONIAL_IMAGE_PATH + "/dulam_c.jpg";

export const JP_PRANAY_PIC = JOB_PROGRAM_TESTIMONIAL_IMAGE_PATH + "/pranay.jpg";

export const JP_PRANAY_C_PIC =
  JOB_PROGRAM_TESTIMONIAL_IMAGE_PATH + "/pranay_c.jpg";

export const JP_VERGES_PIC = JOB_PROGRAM_TESTIMONIAL_IMAGE_PATH + "/verges.jpg";

export const JP_VERGES_C_PIC =
  JOB_PROGRAM_TESTIMONIAL_IMAGE_PATH + "/verges_c.jpg";

// Unscool Advantage ****************************

export const JOB_PROGRAM_ADVANTAGE_IMAGE_PATH =
  JOB_PROGRAM_IMAGE_CDN_ENDPOINT + "/advantage";

export const JP_LEARNERS_PIC =
  JOB_PROGRAM_ADVANTAGE_IMAGE_PATH + "/learners.png";

export const JP_PARTNERS_PIC =
  JOB_PROGRAM_ADVANTAGE_IMAGE_PATH + "/partners.png";

export const JP_ENROLLMENTS_PIC =
  JOB_PROGRAM_ADVANTAGE_IMAGE_PATH + "/enrollments.png";

export const JP_PLACEMENTS_PIC =
  JOB_PROGRAM_ADVANTAGE_IMAGE_PATH + "/plancements.png";

export const JP_INDUSTRY_PIC =
  JOB_PROGRAM_ADVANTAGE_IMAGE_PATH + "/industry.png";

// FC GOA PAGE IMAGE CONSTANTS

export const PARTNERS_IMAGE_CDN_ENDPOINT =
  HTTPS + process.env.GATSBY_PUBLIC_IMAGE_CDN_DOMAIN + "/partners";

export const FC_GOA_IMAGE_CDN_ENDPOINT =
  PARTNERS_IMAGE_CDN_ENDPOINT + "/fc_goa";

// ***************hero

export const HERO_LOGO_IMAGE_PATH =
  FC_GOA_IMAGE_CDN_ENDPOINT + "/hero/digitalxfcgoa.png";

// ************* journey section

export const JOURNEY_IMAGE_PATH = FC_GOA_IMAGE_CDN_ENDPOINT + "/journey";

export const JOURNEY1_IMAGE_PATH = JOURNEY_IMAGE_PATH + "/journey_01.png";
export const JOURNEY2_IMAGE_PATH = JOURNEY_IMAGE_PATH + "/journey_02.png";
export const JOURNEY3_IMAGE_PATH = JOURNEY_IMAGE_PATH + "/journey_03.png";

// ************* coach section

export const COACH_IMAGE_PATH = FC_GOA_IMAGE_CDN_ENDPOINT + "/coaches";

export const ANTONIO_IMAGE_PATH = COACH_IMAGE_PATH + "/Antonio.png";
export const FC_GOA_LOGO_IMAGE_PATH = COACH_IMAGE_PATH + "/fc_goa_logo.png";
export const GAVIN_IMAGE_PATH = COACH_IMAGE_PATH + "/Gavin.png";
export const SHANE_IMAGE_PATH = COACH_IMAGE_PATH + "/Shane.png";
export const ROBERT_IMAGE_PATH = COACH_IMAGE_PATH + "/footballCoach.png";
export const DIGITAL_LOGO_IMAGE_PATH = COACH_IMAGE_PATH + "/digital_coach.png";

export const DAYS = "days";
export const MONTHS = "months";

export const UNSCHOOL_MARKETING_WEBSITE_CDN =
  "https://dgc4ncvkkwqo2.cloudfront.net";

export const SALES_PAGE_FILES_PATH =
  UNSCHOOL_MARKETING_WEBSITE_CDN + "/advanced-sales-certification-1.0";

export const DIGITAL_MARKETING_PAGE_FILES_PATH =
  UNSCHOOL_MARKETING_WEBSITE_CDN + "/digital-marketing-1.0";

export const HOME_LANDING_PAGE_FILES_PATH =
  UNSCHOOL_MARKETING_WEBSITE_CDN + "/home-1.0";

export const INTERNSHIP_PROGRAM_PAGE_FILES_PATH =
  UNSCHOOL_MARKETING_WEBSITE_CDN + "/internship-program-1.0";

export const SKILL_COURSE_PAGE_FILES_PATH =
  UNSCHOOL_MARKETING_WEBSITE_CDN + "/skill-course-page-1.0";

export const INTERNSHIP_COURSE_PAGE_FILES_PATH =
  UNSCHOOL_MARKETING_WEBSITE_CDN + "/internship-course-page-1.0";

export const SKILL_COURSES_LANDING_PAGE_FILES_PATH =
  UNSCHOOL_MARKETING_WEBSITE_CDN + "/skill-courses-landing-page-1.0";

export const BROWSE_PAGE_FILES_PATH =
  UNSCHOOL_MARKETING_WEBSITE_CDN + "/browse";

export const GLOBAL_FILES_PATH = UNSCHOOL_MARKETING_WEBSITE_CDN + "/global";
