import { ACTION_TYPES } from "../actions/types";
import { initialUserPlans } from "../states";

const { SET_USER_PLANS, SET_PLAN_PURCHASE_BILL } = ACTION_TYPES;

const userPlansReducer = (state = initialUserPlans, action) => {
  switch (action.type) {
    case SET_USER_PLANS:
      return { ...state, plans: action.payload };
    case SET_PLAN_PURCHASE_BILL:
      return { ...state, planPurchaseBill: action.payload };
    default:
      return state;
  }
};

export default userPlansReducer;
