import { ACTION_TYPES } from "../actions/types";
import { initialUserCoursesState } from "../states";

const { SET_USER_COURSES, SET_USER_SERIES_COURSES, SET_COURSE_PURCHASE_BILL } =
  ACTION_TYPES;

let initialState = initialUserCoursesState;

const userCoursesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_COURSES:
      return {
        ...state,
        courses: action.payload,
        isCoursesLoading: false,
      };
    case SET_USER_SERIES_COURSES:
      return {
        ...state,
        series: action.payload,
      };
    case SET_COURSE_PURCHASE_BILL:
      return {
        ...state,
        coursePurchaseBill: action.payload,
      };
    default:
      return state;
  }
};

export default userCoursesReducer;
