import Cookies from "js-cookie";
import { AUTH_TOKEN, BASE_DOMAIN, FIRST_LOGIN } from "../utils/localization";

export const setCookie = (token) => {
  process.env.GATSBY_NODE_ENV === "production"
    ? Cookies.set(AUTH_TOKEN, token, {
        expires: 30,
        domain: BASE_DOMAIN,
        path: "/",
      })
    : Cookies.set(AUTH_TOKEN, token, {
        expires: 30,
        path: "/",
      });
};

export const getCookie = () => {
  return Cookies.get(AUTH_TOKEN, { path: "/" });
};

export const removeCookie = () => {
  process.env.GATSBY_NODE_ENV === "production"
    ? Cookies.remove(AUTH_TOKEN, { domain: BASE_DOMAIN, path: "/" })
    : Cookies.remove(AUTH_TOKEN, { path: "/" });
};

export const getFirstTimeLoginStatus = () => {
  return Cookies.get(FIRST_LOGIN) ?? null;
};

export const setFirstTimeLoginStatus = () => {
  process.env.GATSBY_NODE_ENV === "production"
    ? Cookies.set(FIRST_LOGIN, FIRST_LOGIN, {
        expires: 30,
        path: "/",
        domain: BASE_DOMAIN,
      })
    : Cookies.set(FIRST_LOGIN, FIRST_LOGIN, { expires: 30, path: "/" });
};

export const removeFirstTimeLoginStatus = () => {
  process.env.GATSBY_NODE_ENV === "production"
    ? Cookies.remove(FIRST_LOGIN, { path: "/", domain: BASE_DOMAIN })
    : Cookies.remove(FIRST_LOGIN, { path: "/" });
};

export const isUserAuthenticated = () => {
  return getCookie();
};

export const getFormattedLocationList = (locationList) => {
  const formattedLocationListArray = [];
  locationList?.map((state) => {
    state?.children?.map((city) => {
      formattedLocationListArray.push({
        value: `${city?.name}, ${state?.name}`,
        locationId: city?.id,
      });
    });
  });
  return formattedLocationListArray;
};
