import { ACTION_TYPES } from "../actions/types";
import { initialCartState } from "../states";

const { SET_CART_DETAILS, UPDATE_CART_DETAILS, RESET_CART_DETAILS } =
  ACTION_TYPES;

let initialState = initialCartState;

const cartDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART_DETAILS:
      return action.payload;
    case UPDATE_CART_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_CART_DETAILS:
      return initialState;
    default:
      return state;
  }
};

export default cartDetailsReducer;
