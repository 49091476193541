import { modalsInitialState } from "../states";

import { ACTION_TYPES } from "../actions/types";

const {
  OPEN_AUTH_MODAL,
  CLOSE_AUTH_MODAL,
  OPEN_PLANS_MODAL,
  CLOSE_PLANS_MODAL,
  OPEN_SKILL_COURSE_PURCHASE_SUCCESS_MODAL,
  CLOSE_SKILL_COURSE_PURCHASE_SUCCESS_MODAL,
  OPEN_INTERNSHIP_COURSE_PURCHASE_MODAL,
  CLOSE_INTERNSHIP_COURSE_PURCHASE_MODAL,
  OPEN_INTERNSHIP_COURSE_PURCHASE_SUCCESS_MODAL,
  CLOSE_INTERNSHIP_COURSE_PURCHASE_SUCCESS_MODAL,
} = ACTION_TYPES;

const modalsReducer = (state = modalsInitialState, action) => {
  switch (action.type) {
    case OPEN_AUTH_MODAL:
      return { ...state, authModal: true };
    case CLOSE_AUTH_MODAL:
      return { ...state, authModal: false };
    case OPEN_PLANS_MODAL:
      return { ...state, plansModal: true };
    case CLOSE_PLANS_MODAL:
      return { ...state, plansModal: false };
    case OPEN_SKILL_COURSE_PURCHASE_SUCCESS_MODAL:
      return { ...state, skillCoursePurchaseSuccess: true };
    case CLOSE_SKILL_COURSE_PURCHASE_SUCCESS_MODAL:
      return { ...state, skillCoursePurchaseSuccess: false };
    case OPEN_INTERNSHIP_COURSE_PURCHASE_MODAL:
      return { ...state, internshipCoursePurchaseModal: true };
    case CLOSE_INTERNSHIP_COURSE_PURCHASE_MODAL:
      return { ...state, internshipCoursePurchaseModal: false };
    case OPEN_INTERNSHIP_COURSE_PURCHASE_SUCCESS_MODAL:
      return { ...state, internshipCoursePurchaseSuccessModal: true };
    case CLOSE_INTERNSHIP_COURSE_PURCHASE_SUCCESS_MODAL:
      return { ...state, internshipCoursePurchaseSuccessModal: false };
    default:
      return { ...state };
  }
};

export default modalsReducer;
